







// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const tableau: any;
import Vue from "vue";

export default Vue.extend({
  name: "Analysis",
  data() {
    return {
      url: "https://public.tableau.com/views/HCMS-Analytics/SupplyDemandAnalysis",
      options: {}
    };
  },
  mounted: function () {
    this.initViz();
  },
  methods: {
    initViz: function () {
      // eslint-disable-next-line no-undef
      new tableau.Viz(this.$refs.tableau, this.url, this.options);
    }
  }
});
